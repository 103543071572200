<template>
  <Member
    title="Dr. Andrea Cossu"
    description="Chirurgia Generale e Chirurgia Gastroenterologica"
    text="Dal 2015 lavora come chirurgo all’interno dell’UO di Chirurgia Gastroenterologica. Svolge regolarmente come primo e secondo operatore interventi di chirurgia diagnostica e operativa per la patologia esofagea, gastrica, colo-proctologica privilegiando l’approccio mininvasivo. 

È autore e coautore di molteplici pubblicazioni su riviste scientifiche specializzate e partecipa attivamente ai congressi nazionali ed internazionali del settore proponendo i contributi del gruppo.

Svolge l’incarico di Tutor presso Università Vita-Salute nell’ambito della certificazione di abilitazione alla professione medica nell’espletazione dell’esame di Stato."
    :formation="[
      'Laurea in Medicina e Chirurgia – Università degli studi di Cagliari, 2009 ',
      'Specializzazione in Chirurgia dell’apparato digerente – Università degli studi di Cagliari, 2015',
    ]"
    :headerImage="localImage"
    :pub="false"
  />
</template>

<script>
import Member from "../routes/Member";
import teamImage from "@/assets/member-background.jpg";
export default {
  name: "Cossu",
  components: {
    Member,
  },
  data() {
    return {
      localImage: teamImage,
    };
  },
};
</script>
